.option-label{margin-top: 20px;}

.trig .option-label-disabled {
    color: rgba(00, 100, 00) !important;
}

/* .quiz-instruction-list {
    width: 12px;
    height: 12px;
    background-color: #BA1F00;
    display: inline-block;
    border-radius: 50%;
    margin: 0px 10px;
} */

.quiz-instruction-list-shadow {
    box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
}

.quiz-button-color {
    color: white !important;
    background-color: #BA1F00 !important;
}

.quiz-button-outlined-color {
    color: #BA1F00 !important;
    border-color: #BA1F00 !important;
}

